<template>
  <div class="productDetails-container">
      <div class="productImg">
        <div class="header2">
            <van-nav-bar title="产品基础信息" left-text="返回列表"  left-arrow @click-left="onClickLeft"></van-nav-bar>
        </div>
        <div class="title_product">
            {{productList.commodityName}}
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in productList.commodityImgs" :key="index">
                <van-image
                    width="100%"
                    height="100%"
                    @click="showImg"
                    :src="item.url"
                    />
            </van-swipe-item>
        </van-swipe>
      </div>
      <div class="productDescribe">
          <van-cell-group>
            <van-cell title="商品大类:" class="myCell" :value="productList.cctn || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="细分品类:" class="myCell" :value="productList.commodityContractTypeName || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="所属品牌:" class="myCell" :value="productList.name || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商品特点:" class="myCell" :value="productList.description || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商品证书:" class="myCell swipe_cs" >
                <div v-if="productList.commodityCertificates">
                    <van-swipe :loop="false" :show-indicators=false> 
                        <van-swipe-item v-for="(item, index) in productList.commodityCertificates" :key="index">
                            <van-image
                                width="70"
                                height="70"
                                @click="showImg"
                                :src="item.url"
                                />
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div v-else>-</div>

            </van-cell>
           </van-cell-group>

          <van-cell-group>
            <van-cell title="质检报告:" class="myCell swipe_cs" value="productList.">
                <div v-if="productList.inspectionReports">
                    <van-swipe :loop="false" :show-indicators=false> 
                        <van-swipe-item v-for="(item,index) in productList.inspectionReports" :key="index">
                            <van-image
                                width="70"
                                height="70"
                                v-if="item.url.indexOf('.pdf') == -1"
                                @click="showImg"
                                :src="item.url"
                                />
                                <a :href="item.url" target="_black" v-else style="padding: 4px 8px; font-size: 12px; background-color: #e69329; border-radius: 50px; display: inline-block; color: white;">查看PDF</a>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div v-else>
                    -
                </div>
            </van-cell>
           </van-cell-group>

          <van-cell-group>
            <van-cell title="审核状态:" class="myCell" :value="productList.statusName" style="margin-bottom: 100px;"></van-cell>
           </van-cell-group>
      </div>
  </div>
</template>
<script>
import {ImagePreview} from 'vant'
import {Toast} from 'vant';
export default {
    data(){
        return{
            imgList:[],
            productList: [],
        }
    },
    created(){
        Toast.loading({
            message: '加载中...',
            overlay: true,
            duration: 300,
            forbidClick: true,
        });
        setTimeout(()=>{
            this.getProductList();
        }, 100)
    },
    methods:{
        showImg(e){
            this.imgList.push(e.target.src)
            ImagePreview(this.imgList)
            this.imgList = [];
        },
        // 返回列表
        onClickLeft(){
            this.$router.push({
                path: '/product',
                query: {}
             })
        },
        getProductList(){
            this.$http
            .ajax(
            '/ffkj-main/merchantCommodity/page',
            {
                pageNum: 1,
                pageSize: 5000,
                merchantId: localStorage.getItem('merchantId'),
                pageEnum: 'QUERY_PAGE',
            },
            'get',
            {}
            )
            .then(res => {
            if (res.code == 0) {
                for(var i = 0; i < res.data.list.length; i++){
                    if(res.data.list[i].id == this.$route.query.id){
                        this.productList = res.data.list[i];
                    }
                }
            } else {
                Notify({ type: 'danger', message: res.message })
            }
                var timer = setTimeout(
                    function () {
                    this.loading = false
                    window.clearTimeout(timer)
                    }.bind(this),
                    3000
                )
            })
        }
    }
}
</script>

<style scoped>
</style>